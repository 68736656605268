.spin_wheel_body {
  background-image: url("./assets/background.jpg") !important;
  background-position: center !important;
  /* padding-top: 50px !important; */
}

.App {
  font-family: sans-serif;
  text-align: center;
}
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 5px solid #d7d75c;
  padding: 2px;
}
#wheel {
  display: flex;
}
.box {
  padding: 15px 10px;
  background: #fff;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 650px;
  z-index: 2;
  position: absolute;
}
.box .imageBox {
  text-align: center;
}
.box img {
  width: 350px;
}
.title {
  text-align: center;
  font-size: 32px;
}
.closeContainer {
  display: flex;
  justify-content: center;
}
.closepankaj {
  margin-top: 30px;
  width: 140px;
  height: 54px;
  cursor: pointer;
  font-size: 17px;
  color: #fff;
  border: none;
  outline: none;
  background: #2466ff;
  border-radius: 5px;
}

@media only screen and (max-width: 480px) {
  #canvas {
    padding: 5rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
  }

  .box {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  }

  .title {
    text-align: center;
    font-size: 22px;
  }
}
