/* File: BeautifulForm.css */

.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left !important;
  }
  
  /* .beautiful-form {
    background-color: #fff;
    border: 1px solid #ccc; 
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    width: 600px;
  } */
  
  .form-label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .form-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .form-button {
    background-color: #4caf50;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .form-button:hover {
    background-color: #45a049;
  }
  .slider-label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .slider-input {
    width: 80%;
    margin: 0 auto;
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    border-radius: 5px;
    background: #ddd;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
  }
  
  .slider-input:hover {
    opacity: 1;
  }
  
  .slider-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #4caf50;
    cursor: pointer;
  }
  
  .slider-input::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #4caf50;
    cursor: pointer;
  }
  
  .slider-value {
    font-size: 14px;
    margin-top: 8px;
  }
  .table-card {
    width: 95vw;
    border: 1px solid #ccc;
    padding: 10px;
    margin: 2rem;
  }
  
  .table-header {
    text-align: right;
    margin-bottom: 10px;
  }
  
  button {
    padding: 5px 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  .modal {
    display: block;
    z-index: 1;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content {
    background-color: #fefefe;
    /* margin: 2vh auto; */
    position: fixed;
    left: 50%;
    top: 50%;
    transform:translate(-50%,-50%) ;
    max-height: 95vh;
    overflow: auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc; /* Add border */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 600px;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover {
    color: black;
  }
  